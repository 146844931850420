.Typo {
  th {
    font-size:  1.414em;
    text-align: center;
    padding: 0.5em 1em;
  }
  td {
    padding: 0.5em 1em;
  }
  margin-bottom: 2em;
  width: 80%;
}
